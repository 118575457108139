import React from 'react'
import {useRoutes} from "react-router-dom"
import Homescreen from './Homescreen/Homescreen'

const Allroutes = () => {

    const element = useRoutes([
        {
            path: "/",
            children: [
                {
                    index: true,
                    element: <Homescreen />
                }
            ]
        }
    ])
  return element
}

export default Allroutes