import React from 'react'
import styled from 'styled-components'
import smart from "../Assets/14.jpeg"

const Covered = () => {
  return (
    <Container>
        <Wrapper>
            <Phonehold>
                <Left>
                    <Pay>
                        <h2>Lorem ipsum dolor sit amet.</h2>
                    </Pay>
                    <P>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iure consequuntur fugit facilis placeat voluptas eligendi eum aut, illum sed reprehenderit delectus, deserunt ex beatae maiores.
                    </P>
                    <Button>Lorem.</Button>
                </Left>
                <Right>
                    <Img src={smart} />
                </Right>
            </Phonehold>
        </Wrapper>
    </Container>
  )
}

export default Covered
const Img = styled.img`
    height: 100%;
    width: 100%;
    /* margin-top: 30px; */
    @media screen and (max-width: 320px) {
        height: 250px;
    }
    @media screen and (max-width: 375px) {
        height: 270px;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        height: 450px;
        margin-top: 10px;
    }
`
const Right = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: flex-end;
    @media screen and (max-width: 320px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }
    @media screen and (max-width: 904px) {
        width: 100%;
        /* justify-content: flex-end; */
    }
`
const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 43px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #219653;
    color: white;
    margin-top: 50px;
    margin-left: 80px;
    @media screen and (max-width: 904px) {
        margin-top: 30px;
        margin-left: 5px;
    }
`
const P = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 1.50rem;
    margin-left: 80px;
    width: 80%;
    @media screen and (max-width: 904px) {
        width: 100%;
        margin-left: 5px;
    }
    @media screen and (max-width: 375px) {
        text-align: center;
    }
    @media screen and (max-width: 425px) {
        text-align: center;
    }
`
const Pay = styled.div`
    width: 70%;
    margin-left: 80px;
    flex-wrap: wrap;
    h2{
        font-weight: 700;
        font-size: 38px;
        line-height: 2.5rem;
        @media screen and (max-width: 904px) {
        font-size: 30px;
        color: #3d3d3d;
        @media screen and (max-width: 320px) {
        font-size: 27px;
    }
    @media screen and (max-width: 375px) {
        font-size: 25px;
    }
    }
    }
    @media screen and (max-width: 904px) {
        width: 100%;
        margin-left: 5px;
    }
    @media screen and (max-width: 320px) {
        text-align: center;
    }
    @media screen and (max-width: 375px) {
        text-align: center;
    }
    @media screen and (max-width: 425px) {
        text-align: center;
    }
`
const Left = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    @media screen and (max-width: 904px) {
        width: 100%;
    }
`
const Phonehold = styled.div`
    width: 88%;
    display: flex;
    align-items: center;
    background-color: #345844;
    /* margin-top: 60px; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* padding-top: 60px; */
    overflow: hidden;
    flex-wrap: wrap;
    border-radius: 20px;
    @media screen and (max-width: 904px) {
        width: 100%;
        padding: 10px;
    }
`

const Wrapper = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
`