import React from 'react'
import styled from 'styled-components'
import all from "../Assets/all.png"
import craft from "../Assets/crafts.png"
import fbn from "../Assets/fbn.png"
import vu from "../Assets/vu.jpg"
import tiz from "../Assets/tiz.jpg"
import one from "../Assets/partners1.jpg"
import two from "../Assets/partners2.jpg"
import three from "../Assets/partners3.jpg"
import four from "../Assets/partners4.jpg"
import five from "../Assets/partners5.jpg"
import six from "../Assets/partners6.jpg"

const Partners = () => {
  return (
    <Container>
        <Wrapper>
            <Top><h2>Our Partners</h2></Top>
            <P>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores vel quae fugiat illum velit a.
            </P>
            <Imagehold>
                <Up>
                    <Card>
                        <All src={one} />
                    </Card>
                    <Card>
                        <All src={two} />
                    </Card>
                    <Card>
                        <All src={three} />
                    </Card>
                </Up>
                <Down>
                    <Card>
                        <All src={four} />
                    </Card>
                    <Card>
                        <All src={five} />
                    </Card>
                    <Card>
                        <All src={six} />
                    </Card>
                </Down>
            </Imagehold>
        </Wrapper>
    </Container>
  )
}

export default Partners
const Card = styled.div`
    width: 270px;
    height: 200px;
    display: flex;
    border-radius: 10px;
    border: 1px solid #00A859;
`
const Down = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`
const Tiz = styled.img`
    height: 85px;
`
const Vu = styled.img`
    height: 85px;
`
const Fbn = styled.img`
    height: 85px;
`
const Craft = styled.img`
    height: 75px;
`
const All = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const Up = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const Imagehold = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 80px;
    /* background-color: red; */
    margin-top: 50px;
`
const P = styled.div`
    font-weight: 500;
    font-size: 19px;
    line-height: 1.25rem;
    margin-left: 80px;
    /* background-color: red; */
    width: 85%;
`

const Top = styled.div`
    h2{
        font-weight: 700;
        font-size: 43px;
        line-height: 2rem;
    }
    margin-left: 80px;
`

const Wrapper = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    justify-content: center;
    padding-top: 55px;
    padding-bottom: 25px;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
`